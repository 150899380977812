import React from "react";
import {
  EnvelopeSimple,
  FacebookLogo,
  InstagramLogo,
  Phone,
  TiktokLogo,
} from "phosphor-react";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <h6>© {currentYear} QR Подаръци</h6>
      <p style={{ fontSize: "14px" }}>
        Продуктът е собственост на Теонибайт ЕООД
      </p>
      <div className="footer-social-icons">
        <a href="tel:+359877277071" target="_blank">
          <Phone size={32} />
        </a>
        <a
          href="mailto:admin@teonibyte.com?subject=Въпрос%20към%20екипа%20на%20QR%20подаръци"
          target="_blank"
        >
          <EnvelopeSimple size={32} />
        </a>
        <a
          href="https://www.facebook.com/people/QR-%D0%9F%D0%BE%D0%B4%D0%B0%D1%80%D1%8A%D1%86%D0%B8/61560692463792/"
          target="_blank"
        >
          <FacebookLogo size={32} />
        </a>
        <a href="https://www.instagram.com/quickresponsegifts" target="_blank">
          <InstagramLogo size={32} />
        </a>
        <a href="https://www.tiktok.com/@qr.gifts0" target="_blank">
          <TiktokLogo size={32} />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
