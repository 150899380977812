import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Footer from "./Footer";
import {
  FaThumbsDown,
  FaRegThumbsDown,
  FaThumbsUp,
  FaRegThumbsUp,
} from "react-icons/fa6";

function Discovery() {
  const params = useParams();
  const themeID = params.themeID;

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${day}-${month}-${year}`;

  const [discovery, setDiscovery] = useState();
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`/api/themes/${themeID}/discoveries/next`)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error("Няма открито съдържание за деня");
          } else if (response.status === 401) {
            throw new Error("Нямате достъп до това съдържание");
          }

          throw new Error("Неочаквана грешка при заявка към системата");
        }

        return response.json();
      })
      .then((data) => {
        setDiscovery(data);
      })
      .catch((err) => {
        setError(err?.message || "Неочаквана грешка");
      });
  }, [themeID]);

  const handleThumbsUp = () => {
    return fetch(`/api/themes/${themeID}/discoveries/${discovery.id}/like`, {
      method: "PUT",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Неочаквана грешка, моля рефрешнете страницата");
        }

        setDiscovery({
          ...discovery,
          user_metadata: {
            ...discovery.user_metadata,
            like_exists: true,
            dislike_exists: false,
          },
        });
      })
      .catch((err) => {
        setError(err?.message || "Неочаквана грешка");
      });
  };

  const handleThumbsDown = () => {
    return fetch(`/api/themes/${themeID}/discoveries/${discovery.id}/dislike`, {
      method: "PUT",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Неочаквана грешка, моля рефрешнете страницата");
        }

        setDiscovery({
          ...discovery,
          user_metadata: {
            ...discovery.user_metadata,
            dislike_exists: true,
            like_exists: false,
          },
        });
      })
      .catch((err) => {
        setError(err?.message || "Неочаквана грешка");
      });
  };

  return (
    <section className="discovery-section">
      <nav className="discovery-section-nav">
        <div className="nav-block">
          <a href="/" target="_blank">
            <img src="/logo-with-heading.png" className="nav-logo" />
          </a>
        </div>
      </nav>

      <div className="discovery-section-block">
        {error && <span className="discovery-error">{error}</span>}

        {discovery && !error && (
          <>
            <div className="discovery-header-block">
              <h2 className="discovery-header">
                {getHeaderFromType(discovery.type, setError)}
              </h2>
              <h6 className="discovery-subheader">{formattedDate}</h6>
            </div>

            {discovery?.warning && (
              <p className="discovery-warning">{discovery.warning}</p>
            )}

            <div className="discovery-content-text-block">
              {discovery?.user_metadata?.dislike_exists ? (
                <FaThumbsDown
                  className="discovery-content-text-thumbs-down"
                  onClick={handleThumbsDown}
                />
              ) : (
                <FaRegThumbsDown
                  className="discovery-content-text-thumbs-down"
                  onClick={handleThumbsDown}
                />
              )}

              <p className="discovery-content-text">{discovery.content}</p>

              {discovery?.user_metadata?.like_exists ? (
                <FaThumbsUp
                  className="discovery-content-text-thumbs-up"
                  onClick={handleThumbsUp}
                />
              ) : (
                <FaRegThumbsUp
                  className="discovery-content-text-thumbs-up"
                  onClick={handleThumbsUp}
                />
              )}
            </div>

            {discovery.source && (
              <div className="discovery-content-source">
                {discovery.source.link ? (
                  <>
                    <p className="discovery-content-source-text">Източник:</p>
                    <NavLink
                      to={discovery.source.link}
                      target="_blank"
                      className="discovery-content-source-link"
                    >
                      {discovery.source.text}
                    </NavLink>
                  </>
                ) : (
                  <p className="discovery-content-source-text">
                    Източник: {discovery.source.text}
                  </p>
                )}
              </div>
            )}

            <h6 className="discovery-extra-subheader">
              {getSubheadingFromType(discovery.type, setError)}
            </h6>
          </>
        )}
      </div>
      <div className="discovery-footer-block">
        <Footer />
      </div>
    </section>
  );
}

function getHeaderFromType(discoveryType, setError) {
  switch (discoveryType) {
    case "joke":
      return "Виц на Деня";
    case "fortune":
      return "Късмет на деня";
    case "fact":
      return "Дневна доза знание";
    case "thought":
      return "Мисъл на Деня";
    default:
      setError("Непознат тип съдържание");
  }
}

function getSubheadingFromType(discoveryType, setError) {
  switch (discoveryType) {
    case "joke":
      return "Сканирай QR кода и утре за нов виц!";
    case "fortune":
      return "Сканирай QR кода и утре за ново късметче!";
    case "fact":
      return "Сканирай QR кода и утре, за да научиш нещо ново!";
    case "thought":
      return "Сканирай QR кода и утре за нова мисъл!";
    default:
      setError("Непознат тип съдържание");
  }
}

export default Discovery;
