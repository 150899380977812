import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet";
import {
  Info,
  Gift,
  Truck,
  CaretRight,
  CaretLeft,
  FacebookLogo,
  InstagramLogo,
  Phone,
  EnvelopeSimple,
  TiktokLogo,
} from "phosphor-react";
import Footer from "./Footer";
import { premadePuzzleTypes } from "./utils";

function QRPuzzles() {
  const infoRef = useRef(null);
  const productsRef = useRef(null);
  const ordersRef = useRef(null);

  const navigateToRef = (ref, block = "center") => {
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: block,
    });
  };

  const navigateToOrdersRef = () => {
    navigateToRef(ordersRef, "start");
  };

  const gallery = premadePuzzleTypes.map(
    (puzzleType) => `gallery-${puzzleType}.png`
  );

  const [currentGalleryIndex, setCurrentGalleryIndex] = useState(0);
  const goToPrevGalleryIndex = () => {
    setCurrentGalleryIndex((curr) => Math.max(curr - 1, 0));
  };
  const goToNextGalleryIndex = () => {
    setCurrentGalleryIndex((curr) => Math.min(curr + 1, gallery.length - 1));
  };

  return (
    <>
      <Helmet>
        <title>QR Пъзели - Теонибайт ЕООД</title>
        <meta
          name="description"
          content="Открийте удоволствието от създаването на персонализирани QR пъзели и зарадвайте вашите близки и приятели. Изберете любимите си снимки, видеа или песни и ги превърнете в незабравими дигитални спомени или подарете вашия ваучер по уникален и забавен начин."
        />
      </Helmet>
      <nav className="fixed-nav">
        <div className="nav-block">
          <img src="/logo-with-heading.png" className="nav-logo" />
        </div>

        <div className="nav-block">
          <button className="nav-button" onClick={() => navigateToOrdersRef()}>
            Поръчай сега
          </button>
        </div>
      </nav>

      <section>
        <div className="section-block section-limited-block">
          <h1>Персонализирани QR пъзели</h1>

          <p>
            Потопете се в магията на творчеството с нашите подаръчни QR пъзели -
            пресъздайте вашите любими снимки, видеа, песни и други дигитални
            спомени или подарете ваучер по уникален и забавен начин.
          </p>

          <div className="heading-section-nav">
            <span
              className="heading-section-nav-link"
              onClick={() => navigateToRef(infoRef)}
            >
              <Info size={20} />
              <span className="heading-section-nav-link-text"> Информация</span>
            </span>
            <span
              className="heading-section-nav-link"
              onClick={() => navigateToRef(productsRef)}
            >
              <Gift size={20} />
              <span className="heading-section-nav-link-text"> Пъзели</span>
            </span>
            <span
              className="heading-section-nav-link"
              onClick={() => navigateToOrdersRef()}
            >
              <Truck size={20} />
              <span className="heading-section-nav-link-text"> Поръчки</span>
            </span>
          </div>
        </div>

        <div className="large-screen-content">
          <img src="/heading-image.png" className="heading-section-image" />
        </div>
      </section>

      <section ref={infoRef}>
        <div className="large-screen-content">
          <img src="/instructions-image.png" className="instructions-image" />
        </div>
        <div className="section-block section-limited-block">
          <h2>Как работят QR пъзелите</h2>

          <div className="section-block-badge-paragraph">
            <span className="badge">1</span>
            <p>
              Изпратете ни вашия дигитален спомен или предварително закупен
              ваучер и изберете дизайн за пъзела или качете ваша снимка, по
              която да бъде изработен пъзела
            </p>
          </div>
          <div className="section-block-badge-paragraph">
            <span className="badge">2</span>
            <p>Поръчайте вашия QR пъзел</p>
          </div>
          <div className="section-block-badge-paragraph">
            <span className="badge">3</span>
            <p>Подарете QR пъзела на вашите близки</p>
          </div>
          <div className="section-block-badge-paragraph">
            <span className="badge">4</span>
            <p>
              Вашите близки сканират QR кода от пъзела и се наслаждават на
              изненадата
            </p>
          </div>

          <span
            className="badge pointer section-next-link"
            onClick={() => navigateToRef(productsRef)}
          >
            Към пъзелите
          </span>
        </div>
      </section>

      <section ref={productsRef}>
        <div className="section-block section-limited-block">
          <h2>Нашите дизайни</h2>

          <p>
            Всички наши дизайни са съчетани с QR код, към който вие прикачвате
            вашия дигитален спомен или ваучер.
          </p>

          <p>
            Предлагаме и опция за изработка на QR пъзел по ваш дизайн. След като
            ни изпратите ваша снимка, ние ще добавим артистичен QR код към нея
            преди да я изпратим за печат.
          </p>

          <p>
            <strong>ВАЖНО:</strong> съдържанието на всеки QR код е активно за
            период от 2 години от датата на получаване. След изтичане на този
            период, ще се свържем с вас, за да разберем дали искате да удължите
            периода срещу допълнително заплащане или желаете съдържанието да
            бъде изтрито.
          </p>
        </div>

        <div className="carousel">
          <span
            className="carousel-prev"
            style={currentGalleryIndex == 0 ? { display: "none" } : {}}
            onClick={goToPrevGalleryIndex}
          >
            <CaretLeft size={20} weight="bold" />
          </span>
          <div className="gallery-block">
            <img
              src={gallery[currentGalleryIndex]}
              className="carousel-image"
            />
          </div>
          <span
            className="carousel-next"
            style={
              currentGalleryIndex == gallery.length - 1
                ? { display: "none" }
                : {}
            }
            onClick={goToNextGalleryIndex}
          >
            <CaretRight size={20} weight="bold" />
          </span>
        </div>
        <span
          className="badge pointer section-next-link small-screen-content"
          onClick={() => navigateToOrdersRef()}
        >
          Поръчай сега
        </span>
      </section>

      <section ref={ordersRef}>
        <div className="section-block order-section-block">
          <h3>Направи заявка</h3>

          <p>
            Изработката и доставката на QR пъзелите обикновено отнема от 2 до 3
            работни дни. Моля свържете се с нас чрез Facebook, Instagram или
            TikTok за поръчки и заявки:
          </p>

          <div className="footer-social-icons">
            <a
              href="https://www.facebook.com/people/QR-%D0%9F%D0%BE%D0%B4%D0%B0%D1%80%D1%8A%D1%86%D0%B8/61560692463792/"
              target="_blank"
            >
              <FacebookLogo size={32} />
            </a>
            <a
              href="https://www.instagram.com/quickresponsegifts"
              target="_blank"
            >
              <InstagramLogo size={32} />
            </a>
            <a href="https://www.tiktok.com/@qr.gifts0" target="_blank">
              <TiktokLogo size={32} />
            </a>
          </div>

          <p>
            За въпроси или нужда от допълнителна информация можете да се
            свържете с нас чрез обаждане или имейл:
          </p>

          <div className="footer-social-icons">
            <a href="tel:+359877277071" target="_blank">
              <Phone size={32} />
            </a>
            <a
              href="mailto:admin@teonibyte.com?subject=Въпрос%20към%20екипа%20на%20QR%20подаръци"
              target="_blank"
            >
              <EnvelopeSimple size={32} />
            </a>
          </div>

          <p>
            <strong>ВАЖНО:</strong> Във Facebook, Instagram и TikTok профилите
            ни ще намерите още снимки и видеа на нашите QR пъзели. Последвайте
            страниците ни, за да бъдете в течение на всички новости и промоции.
          </p>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default QRPuzzles;
