import React from "react";
import "./App.css";
import Home from "./Home";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import Access from "./Access";
import Discovery from "./Discovery";
import QRPuzzles from "./QRPuzzles";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/qr-puzzles" replace />,
  },
  {
    path: "/qr-puzzles",
    element: <QRPuzzles />,
  },
  {
    path: "/access/:qrCodeID",
    element: <Access />,
  },
  {
    path: "/themes/:themeID/discoveries/next",
    element: <Discovery />,
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
